import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "shared/components/common/base-entity-link";

import { HeaderAllMenuTitle } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common/header-mega-menu-title";
import type { headerAllMenuColumnistsQuery$key } from "scmp-app/queries/__generated__/headerAllMenuColumnistsQuery.graphql";

import {
  AuthorName,
  Container,
  LinkContainer,
  StyledAuthorAvatar,
  StyledEntityLink,
} from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuColumnistsQuery$key;
};

export const HeaderAllMenuColumnists: FunctionComponent<Props> = ({
  className,
  queryParameter,
  reference: reference_,
}) => {
  const queue = useFragment(
    graphql`
      fragment headerAllMenuColumnistsQuery on Query {
        columnistsQueue: queue(filter: { name: "scmp_columnists" }) {
          items(first: 7) {
            edges {
              node {
                ... on Author {
                  ...entityLink
                  ...authorAvatarAuthor
                  name
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  return (
    <Container className={className}>
      <HeaderAllMenuTitle>SCMP Columnists</HeaderAllMenuTitle>

      <LinkContainer>
        {queue.columnistsQueue?.items?.edges.map((edge, index) => (
          <StyledEntityLink key={index} query={queryParameter} reference={edge.node}>
            <StyledAuthorAvatar
              disableLinkingData
              height={40}
              reference={edge.node}
              width={40}
              withoutLink
            />
            <AuthorName>{edge.node.name}</AuthorName>
          </StyledEntityLink>
        ))}
      </LinkContainer>
    </Container>
  );
};

HeaderAllMenuColumnists.displayName = "HeaderAllMenuColumnists";
