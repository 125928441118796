import { ClickAwayListener } from "@mui/base";
import { theme, useResponsive } from "@product/scmp-sdk";
import { useSyncedRef } from "@react-hookz/web";
import { useSetAtom } from "jotai";
import { usePopupState } from "material-ui-popup-state/hooks";
import type { FunctionComponent, RefObject } from "react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";

import { useCurrentPageType } from "shared/hooks";
import { useEditionValue } from "shared/lib/edition";

import { useAppBarContext } from "scmp-app/components/app-bar/contexts";
import { centerAlignModifier } from "scmp-app/components/common/popper/modifiers";
import { query } from "scmp-app/components/header/header-oneline-menu-container";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { headerAllMenuQuery$key } from "scmp-app/queries/__generated__/headerAllMenuQuery.graphql";
import type { headerOnelineMenuContainerQuery } from "scmp-app/queries/__generated__/headerOnelineMenuContainerQuery.graphql";

import { headerAllMenuAtom } from "./atoms";
import {
  HeaderAllMenuTopicsAndFocus,
  MegaMenuBottomBar,
  MegaMenuContainer,
  MegaMenuWrapper,
  StyledFade,
  StyledHeaderAllMenuApplications,
  StyledHeaderAllMenuColumnists,
  StyledHeaderAllMenuEdition,
  StyledHeaderAllMenuEpaper,
  StyledHeaderAllMenuFocus,
  StyledHeaderAllMenuHighlight,
  StyledHeaderAllMenuSection,
  StyledHeaderAllMenuTopics,
  StyledIconArrow,
  StyledPopper,
  Title,
  ToggleContainer,
} from "./styles";

export type Props = {
  className?: string;
  containerReference: RefObject<HTMLDivElement>;
  queryReference: PreloadedQuery<headerOnelineMenuContainerQuery>;
};

export const HeaderAllMenu: FunctionComponent<Props> = ({
  className,
  containerReference,
  queryReference,
}) => {
  const data = usePreloadedQuery(query, queryReference);
  const queries = useFragment<headerAllMenuQuery$key>(
    graphql`
      fragment headerAllMenuQuery on Query {
        ...headerAllMenuColumnistsQuery
        ...headerAllMenuFocusQuery
        ...headerAllMenuHighlightsQuery
        ...headerAllMenuSectionAppConfigQuery
        ...headerAllMenuTopicsQuery
      }
    `,
    data,
  );

  const popupId = "tooltip-mega-menu";
  const modifiers = useMemo(() => [{ enabled: false, name: "flip" }, centerAlignModifier], []);
  const popupState = usePopupState({ popupId, variant: "popper" });
  const setHeaderAllMenuState = useSetAtom(headerAllMenuAtom);
  const isXLargeDesktopUp = useResponsive(theme.breakpoints.up("xLargeDesktop"));

  useEffect(() => {
    setHeaderAllMenuState(current => ({ ...current, isOpen: popupState.isOpen }));
  }, [popupState.isOpen, setHeaderAllMenuState]);

  const targetReference = useRef<HTMLDivElement>(null);

  const currentContainerReference = useMemo(
    () => (isXLargeDesktopUp ? containerReference.current : null),
    [containerReference, isXLargeDesktopUp],
  );

  const handleClose = useCallback(
    (event: Event | React.SyntheticEvent) => {
      // Prevent the menu getting close when clicking the target
      if (
        targetReference.current &&
        targetReference.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      popupState.setOpen(false);
    },
    [popupState],
  );

  const useHideMenuEffect = () => {
    const { shouldSwap } = useAppBarContext();
    const setOpenReference = useSyncedRef(popupState.setOpen);
    const isDesktopDown = useResponsive(theme.breakpoints.down("desktop"));

    // Hide the menu when swapping from/to mini header
    useEffect(() => {
      setOpenReference.current(false);
    }, [shouldSwap, setOpenReference]);

    // Hide the menu when screen size smaller than desktop
    useEffect(() => {
      if (isDesktopDown) setOpenReference.current(false);
    }, [isDesktopDown, setOpenReference]);
  };
  useHideMenuEffect();

  const currentPageType = useCurrentPageType();
  const edition = useEditionValue();
  const queryParameter = useMemo(
    () => ({
      module: `oneline_menu_all_${edition}`,
      pgtype: currentPageType,
    }),
    [currentPageType, edition],
  );

  const sendTracking = useCallback(() => {
    sendGA4Tracking({
      action: "click",
      category: "menu",
      customized_parameters: {
        action_type: popupState.isOpen ? "close" : "open",
        edition,
        menu_type: "oneline",
      },
      subcategory: "section",
    });
  }, [popupState.isOpen, edition]);
  if (!queryReference) return null;

  return (
    <StyledPopper
      bindType="toggle"
      container={currentContainerReference}
      disablePortal={true}
      modifiers={modifiers}
      placement="bottom"
      popupComponent={({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClose}>
          <StyledFade {...TransitionProps} timeout={200}>
            <MegaMenuWrapper>
              <MegaMenuContainer>
                <StyledHeaderAllMenuSection queryParameter={queryParameter} reference={queries} />
                <HeaderAllMenuTopicsAndFocus>
                  <StyledHeaderAllMenuTopics queryParameter={queryParameter} reference={queries} />
                  <StyledHeaderAllMenuFocus queryParameter={queryParameter} reference={queries} />
                  <StyledHeaderAllMenuEpaper />
                </HeaderAllMenuTopicsAndFocus>
                <StyledHeaderAllMenuApplications queryParameter={queryParameter} />
                <StyledHeaderAllMenuColumnists
                  queryParameter={queryParameter}
                  reference={queries}
                />
              </MegaMenuContainer>
              <MegaMenuBottomBar>
                <StyledHeaderAllMenuEdition />
                <StyledHeaderAllMenuHighlight queryParameter={queryParameter} reference={queries} />
              </MegaMenuBottomBar>
            </MegaMenuWrapper>
          </StyledFade>
        </ClickAwayListener>
      )}
      popupId={popupId}
      popupState={popupState}
      sendTrackingHandler={sendTracking}
      transition={true}
      triggerComponent={
        <ToggleContainer $isToggle={popupState.isOpen} className={className} ref={targetReference}>
          <Title>
            All
            <StyledIconArrow $isToggle={popupState.isOpen} />
          </Title>
        </ToggleContainer>
      }
    />
  );
};

HeaderAllMenu.displayName = "HeaderAllMenu";
