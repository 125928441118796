import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "shared/components/common/base-entity-link";

import {
  HeaderAllMenuItemBaseLink,
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";
import type { headerAllMenuSectionAppConfigQuery$key } from "scmp-app/queries/__generated__/headerAllMenuSectionAppConfigQuery.graphql";

import { ItemContainer } from "./styles";

export type Props = {
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuSectionAppConfigQuery$key;
};

export const HeaderAllMenuSection: FunctionComponent<Props> = ({
  queryParameter,
  reference: reference_,
}) => {
  const appConfig = useFragment(
    graphql`
      fragment headerAllMenuSectionAppConfigQuery on Query {
        onelineMenu: appConfig(filter: { entityId: "scmp_pwa_oneline_menu" }) {
          json
        }
      }
    `,
    reference_,
  );

  const onelineMenuAppConfig = appConfig?.onelineMenu?.json as ScmpPwaOnelineMenuAppConfig;
  const allList = onelineMenuAppConfig?.all;
  if (!allList) return null;

  return (
    <>
      {allList.map((listItem, index) => (
        <ItemContainer key={index}>
          <HeaderAllMenuTitle>{listItem.name}</HeaderAllMenuTitle>
          <HeaderAllMenuItemLinkContainer>
            {listItem.items.map((link, index) => (
              <HeaderAllMenuItemBaseLink key={index} linkEntity={link} query={queryParameter}>
                {link.name}
              </HeaderAllMenuItemBaseLink>
            ))}
          </HeaderAllMenuItemLinkContainer>
        </ItemContainer>
      ))}
    </>
  );
};

HeaderAllMenuSection.displayName = "HeaderAllMenuSection";
