import type { FunctionComponent } from "react";
import { useState } from "react";

import { editionOptions, editions, useEdition, useEditionValue } from "shared/lib/edition";

import IconArrow from "./icon-arrow.svg";
import { Container, Edition, EditionMenu, EditionMenuItem, EditionText } from "./styles";

export type Props = {
  className?: string;
};

export const HeaderAllMenuEdition: FunctionComponent<Props> = ({ className }) => {
  const currentEdition = useEditionValue();
  const { update: updateEdition } = useEdition();
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorElement);

  const editionClickedHandler = (edition: string) => {
    updateEdition(edition);
    window.location.href = editions[currentEdition].href;
  };

  return (
    <Container className={className}>
      <EditionText>Edition:</EditionText>
      <Edition
        aria-controls={open ? "edition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        id="menu-edition-button"
        onClick={event => setAnchorElement(event.currentTarget)}
      >
        {editions[currentEdition].label}
        <span>
          <IconArrow />
        </span>
      </Edition>
      <EditionMenu
        anchorEl={anchorElement}
        id="edition-menu"
        MenuListProps={{
          "aria-labelledby": "menu-edition-button",
          sx: {
            padding: "16px 0px",
            width: 124,
          },
        }}
        onClose={() => setAnchorElement(null)}
        open={open}
        sx={{
          "&& .Mui-selected": {
            ":hover": {
              background: "transparent",
            },
            background: "transparent",
            color: "#4585FF",
          },
          ".MuiPaper-root": {
            border: "1px solid #000",
            boxShadow: "none",
            marginTop: "11px",
          },
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        {editionOptions.map(option => (
          <EditionMenuItem
            key={option.value}
            onClick={() => editionClickedHandler(option.value)}
            selected={option.value === currentEdition}
          >
            {option.label}
          </EditionMenuItem>
        ))}
      </EditionMenu>
    </Container>
  );
};

HeaderAllMenuEdition.displayName = "HeaderAllMenuEdition";
