import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { ZIndexLayer } from "shared/lib/styles";

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  inline-size: 162px;
`;

export const EditionText = styled.div`
  color: #001246;
  font-size: 15px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
`;

export const Edition = styled(Button)`
  padding: 0;

  color: #001246;
  font-weight: 700;
  font-size: 15px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  letter-spacing: normal;
  text-transform: capitalize;

  cursor: pointer;

  span {
    display: flex;
    align-items: center;

    margin-inline-start: 4px;
  }
`;

export const EditionMenu = styled(Menu)`
  z-index: ${ZIndexLayer.EditionInvitation};
`;

export const EditionMenuItem = styled(MenuItem)`
  min-block-size: auto;
  padding-block: 8px;
  padding-inline: 20px;

  color: #000000;
  font-size: 16px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;

  &:hover {
    background-color: transparent;
  }
`;
