/**
 * @generated SignedSource<<298a6745a6c259086aef0f62076cefd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type headerAllMenuSectionAppConfigQuery$data = {
  readonly onelineMenu: {
    readonly json: unknown | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "headerAllMenuSectionAppConfigQuery";
};
export type headerAllMenuSectionAppConfigQuery$key = {
  readonly " $data"?: headerAllMenuSectionAppConfigQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerAllMenuSectionAppConfigQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "headerAllMenuSectionAppConfigQuery",
  "selections": [
    {
      "alias": "onelineMenu",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "scmp_pwa_oneline_menu"
          }
        }
      ],
      "concreteType": "AppConfig",
      "kind": "LinkedField",
      "name": "appConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "json",
          "storageKey": null
        }
      ],
      "storageKey": "appConfig(filter:{\"entityId\":\"scmp_pwa_oneline_menu\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "920be0f52242385e73a9744c98bd5535";

export default node;
