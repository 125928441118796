import styled from "@emotion/styled";

import { BaseLink } from "shared/components/common/base-link";

import { HeaderAllMenuItemLinkCss } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";

export const Container = styled.div``;

// Remove this when we can move ePaper items to drupal config
// in that case HeaderAllMenuItemLink should be used
export const StyledLink = styled(BaseLink)`
  ${HeaderAllMenuItemLinkCss}
`;
