import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";

import { AuthorAvatar } from "scmp-app/components/author/author-avatar";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div``;

export const Title = styled.div`
  color: #001246;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  ${theme.breakpoints.up("mediumDesktop")} {
    font-size: 16px;
  }
`;

export const StyledAuthorAvatar = styled(AuthorAvatar)`
  inline-size: 40px;
  block-size: 40px;
  padding: 2px;

  border-radius: 50%;

  background-color: #34b8b5;

  overflow: hidden;
`;

export const AuthorName = styled.span`
  margin-inline-start: 12px;

  color: #000000;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  line-height: 18px;

  ${theme.breakpoints.up("mediumDesktop")} {
    font-size: 16px;
  }
`;

export const StyledEntityLink = styled(EntityLink)`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  &:hover ${AuthorName} {
    color: #2c4692;
    text-decoration: underline;
  }
`;

export const LinkContainer = styled.div`
  ${StyledEntityLink}:not(:last-child) {
    margin-block-end: 12px;
  }
`;
