import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Fade from "@mui/material/Fade";
import { fontRoboto, theme, transientOptions } from "@product/scmp-sdk";

import { BaseEntityLink } from "shared/components/common/base-entity-link";
import { ZIndexLayer } from "shared/lib/styles";

import { Popper } from "scmp-app/components/common/popper";

import { HeaderAllMenuApplications } from "./header-all-menu-applications";
import { HeaderAllMenuColumnists } from "./header-all-menu-columnists";
import { HeaderAllMenuEdition } from "./header-all-menu-edition";
import { HeaderAllMenuEpaper } from "./header-all-menu-epaper";
import { HeaderAllMenuFocus } from "./header-all-menu-focus";
import { HeaderAllMenuHighlights } from "./header-all-menu-highlights";
import { HeaderAllMenuSection } from "./header-all-menu-section";
import { ItemContainer } from "./header-all-menu-section/styles";
import { HeaderAllMenuTopics } from "./header-all-menu-topics";
import ArrowIcon from "./icon-arrow.svg";

export const StyledBaseEntityLink = styled(BaseEntityLink)``;

type ToggleProps = {
  $isToggle: boolean;
};

export const Title = styled.span`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
  align-items: center;

  block-size: 16px;

  color: #4585ff;
  font-weight: 700;
  font-size: 14px;
  font-family: ${fontRoboto};
  line-height: 120%;
`;

export const StyledIconArrow = styled(ArrowIcon, { ...transientOptions })<ToggleProps>`
  transform: rotate(0deg);

  transition: transform 0.2s;
  ${props => {
    if (!props.$isToggle) return;
    return css`
      transform: rotate(180deg);
    `;
  }}
`;

export const ToggleContainer = styled.div<ToggleProps>`
  display: flex;
  align-items: center;

  background: ${props => (props.$isToggle ? "rgba(69, 133, 255, 0.2)" : "unset")};

  cursor: pointer;
`;

export const StyledPopper = styled(Popper)`
  inset-inline-start: 50% !important;
  z-index: ${ZIndexLayer.AppBar};

  inline-size: 100vw !important;
  margin-inline-start: -50vw !important;

  ${theme.breakpoints.up("largeDesktop")} {
    inset-inline-start: 50% !important;

    inline-size: 1440px !important;
    margin-inline-start: -720px !important;
  }
`;

export const MegaMenuWrapper = styled.div`
  margin-block-start: 18px;
  padding-block: 32px 40px;

  background-color: #e5e5e5;

  border-block-end: 1px solid #000000;
`;

export const MegaMenuContainer = styled.div`
  display: grid;
  grid:
    "section-col-1 section-col-3 section-col-4 section-col-5 topics-and-focus application columnists" min-content
    "section-col-2 section-col-3 section-col-4 section-col-5 topics-and-focus application columnists" min-content
    / 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 28px 21px;

  inline-size: 100%;
  max-inline-size: 1272px;
  margin-inline: auto;
  padding-inline: 32px;

  font-size: 14px;

  ${theme.breakpoints.up("desktop")} {
    padding-inline: 48px;
  }

  ${theme.breakpoints.up("mediumDesktop")} {
    grid:
      "section-col-1 section-col-2 section-col-4 section-col-5 topics-and-focus application columnists" min-content
      "section-col-1 section-col-3 section-col-4 section-col-5 topics-and-focus application columnists" min-content
      / 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

    font-size: 16px;
  }

  ${ItemContainer}:nth-child(1) {
    grid-area: section-col-1;
  }
  ${ItemContainer}:nth-child(2) {
    grid-area: section-col-2;
  }
  ${ItemContainer}:nth-child(3) {
    grid-area: section-col-3;
  }
  ${ItemContainer}:nth-child(4) {
    grid-area: section-col-4;
  }
  ${ItemContainer}:nth-child(5) {
    grid-area: section-col-5;
  }
`;

export const StyledHeaderAllMenuApplications = styled(HeaderAllMenuApplications)`
  grid-area: application;
`;
export const StyledHeaderAllMenuColumnists = styled(HeaderAllMenuColumnists)`
  grid-area: columnists;
`;
export const StyledHeaderAllMenuFocus = styled(HeaderAllMenuFocus)`
  grid-area: focus;
`;
export const StyledHeaderAllMenuEpaper = styled(HeaderAllMenuEpaper)`
  grid-area: epaper;
`;
export const StyledHeaderAllMenuSection = styled(HeaderAllMenuSection)`
  grid-area: section;
`;
export const StyledHeaderAllMenuTopics = styled(HeaderAllMenuTopics)`
  grid-area: topics;
`;

export const HeaderAllMenuTopicsAndFocus = styled.div`
  grid-area: topics-and-focus;

  display: grid;
  grid:
    "topics" min-content
    "focus" min-content
    "epaper" min-content
    / fit-content(100%);
  row-gap: 28px;
`;

export const StyledHeaderAllMenuEdition = styled(HeaderAllMenuEdition)``;

export const StyledHeaderAllMenuHighlight = styled(HeaderAllMenuHighlights)`
  flex: 1;
`;

export const StyledFade = styled(Fade)`
  max-inline-size: 1616px;
  margin-inline: auto;
`;

export const MegaMenuBottomBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 21px;
  justify-content: space-between;

  inline-size: 100%;
  max-inline-size: 1272px;
  margin-block-start: 28px;
  margin-inline: auto;
  padding-inline: 32px;

  ${theme.breakpoints.up("desktop")} {
    padding-inline: 48px;
  }
`;
